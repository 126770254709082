;(function () {
  $('[data-slider="bargain"]').each(function () {

    var slider = $(this);
    var breakPoints = slider.find('[data-slider-slides]').attr('data-slider-slides');

    slider.find('[data-slider-slides]').slick({
      dots: false,
      arrows: true,
      infinite: false,
      adaptiveHeight: true,
      slidesToShow: $.mlsSlider.getFirstCol(breakPoints),
      autoplay: false,
      autoplaySpeed: 3000,
      swipeToSlide: true,
      rows: 1,
      prevArrow: slider.find('[data-slider-arrow-left]').removeClass('hidden'),
      nextArrow: slider.find('[data-slider-arrow-right]').removeClass('hidden'),
      responsive: $.mlsSlider.getCols(breakPoints)
    }).find('[data-slider-slide]').removeClass('hidden');

  });

})();